import React, { useEffect } from 'react'
import { UilArrowUp } from '@iconscout/react-unicons'

const ScrollTop = () => {
  useEffect(()=>{
    const scrollUp = () => {
      const scrollUp = document.getElementById('scroll-up')
      if (window.scrollY >= 300) scrollUp.classList.add('show-scroll')
      else scrollUp.classList.remove('show-scroll')
    }
    
    window.addEventListener('scroll', scrollUp)
  }, []);

  return (
    <a href="#home" className="scrollup" id="scroll-up" >
      <UilArrowUp className="scrollup__icon" />
    </a>
  )
}

export default ScrollTop