const AboutBlock = (aboutBlock) => {
  return (
    <>
      <div className="about__text">
        <p className="part-one">{aboutBlock.values.partOne}</p>
        <p className="part-two">{aboutBlock.values.partTwo}</p>
      </div>
      <div className="about__img">
        <img src={aboutBlock.values.img} alt="Avatar" className="about__image" />
      </div>
    </>
  )
}

export default AboutBlock