import Home from './main/Home'
import Introduction from './main/Introduction'
import About from './main/About'
import Sales from './main/Sales'
import Contact from './main/Contact'
import ScrollTop from './components/ScrollTop'
import ImageBackgroundOne from './components/ImageBackgroundOne'

const Main = () => {
  return (
    <main className="main" id="main">
      <Home />
      <Introduction />
      <ImageBackgroundOne />
      <About />
      <Sales />
      <Contact />
      <ScrollTop />
    </main>
  )
}

export default Main