import React, { useEffect} from 'react'
import { UilTimes, UilApps } from '@iconscout/react-unicons'
import navItemsData from '../../../data/navItemsData'
import NavItem from './NavItem'
import LogoSvg from '../svg/LogoSvg'

const NavBar = () => {
  let navItems = []
  let menuCount = navItemsData.length
  let menuMiddleCount = menuCount / 2
  let counter = 0
  let logoSet = false

  navItemsData.forEach(navItem => {
    counter++
    if (!logoSet && counter > menuMiddleCount) {
      navItems.push(
        <li key="home" className="nav__item-logo">
          <a href='#home'>
            <LogoSvg />
          </a>
        </li>
      ) 
      logoSet = true
    }
    navItems.push(
      <NavItem key={navItem.id} values={navItem} />
    )
  })

  useEffect(()=>{
    const toggleMenu = () => {
      document.getElementById('nav-menu').classList.toggle('show-menu')
    }

    const linkAction = () => {
      const navMenu = document.getElementById('nav-menu')
      navMenu.classList.remove('show-menu')
    }
    document.querySelectorAll('.nav__link').forEach(n => n.addEventListener('click', linkAction))

    // const scrollActive = () => {
    //   const sections = document.querySelectorAll('section[id]')
    //   const scrollY = window.pageYOffset
  
    //   sections.forEach(current => {
    //     const sectionHeight = current.offsetHeight
    //     const sectionTop = current.offsetTop - 50;
    //     let sectionId = current.getAttribute('id')

    //     if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
    //       document.querySelector('.nav__menu a[href*=' + sectionId + ']').classList.add('active-link')
    //     } else {
    //       document.querySelector('.nav__menu a[href*=' + sectionId + ']').classList.remove('active-link')
    //     }
    //   })
    // }

    const scrollHeader = () => {
      const nav = document.getElementById('header')
      if (window.scrollY >= 80) nav.classList.add('scroll-header')
      else nav.classList.remove('scroll-header')
    }

    window.addEventListener('scroll', scrollHeader)
    // window.addEventListener('scroll', scrollActive)
    document.getElementById('nav-close').addEventListener('click', toggleMenu)
    document.getElementById('nav-toggle').addEventListener('click', toggleMenu)
  }, []);

  return (
    <nav className="nav container">
      <a href='#home' className="nav__logo">The Hotel Lobby</a>
      <div className="nav__menu" id="nav-menu">
        <ul className="nav__list grid">
          {navItems}
          <UilTimes className="nav__close" id="nav-close" />
        </ul>
      </div>
      <div className="nav__btns">
        <div id="nav-toggle">
          <UilApps className="nav__toggle" />
        </div>
      </div>
    </nav>
  )
}

export default NavBar