import { PopupButton } from 'react-calendly'

const AppointmentButton = () => {
  return (
    <div className="btn__container-appointment">
      <PopupButton
        url="https://calendly.com/thehotellobby"
        rootElement={document.getElementById("root")}
        text="Plan je afspraak"
        className="btn-appointment"
      />
    </div>
  )
}

export default AppointmentButton