import NavBar from './components/navigation/NavBar'

const Header = () => {
  return (
    <header className="header" id="header">
      <NavBar />
    </header> 
  )
}

export default Header