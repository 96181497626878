import AppointmentButton from "../AppointmentButton"
import BlockGreen from './BlockGreen'
import BlockOrange from './BlockOrange'
import BlockStarList from "./BlockStarList"

const SalesBlock = (salesData) => {
  const listItems = []

  salesData.values.list.forEach(listItem => {
    if (salesData.values.type === "GreenBlock") {
      listItems.push(
        <BlockGreen key={listItem.id} values={listItem.text} />
      )
    } else if (salesData.values.type === "OrangeBlock") {
      listItems.push(
        <BlockOrange key={listItem.id} values={listItem.text} />
      )
    } else if (salesData.values.type === "StarList") {
      listItems.push(
        <BlockStarList key={listItem.id} values={listItem.text} />
      )
    }
  })

  return (
    <div className="section__wrapper">
      <div className="sales__container container">
        <h2 className="section__title">{salesData.values.title}</h2>
        <div className="sales__wrapper">
          {listItems}
        </div>
        {salesData.values.type !== "StarList" ? <AppointmentButton /> : ""}
      </div>
    </div>
  )
}

export default SalesBlock