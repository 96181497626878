import { UilCommentLines, UilUser, UilArrowGrowth, UilMailbox } from '@iconscout/react-unicons'

const navItemsData = [
  // {
  //   id:0,
  //   title:'Home',
  //   href:'#home',
  //   icon:UilEstate
  // },
  {
    id:1,
    title:'Introductie',
    href:'#introduction',
    icon:UilCommentLines
  },
  {
    id:2,
    title:'Over mij',
    href:'#about',
    icon:UilUser
  },
  {
    id:3,
    title:'Sales',
    href:'#sales',
    icon:UilArrowGrowth
  },
  {
    id:4,
    title:'Contact',
    href:'#contact',
    icon:UilMailbox
  }
]

export default navItemsData