import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { string, z } from 'zod'
import emailjs from '@emailjs/browser'
import { UilSpinner } from '@iconscout/react-unicons'

const schema = z.object({
  email: string().email()
})

const Newsletter = () => {
  const form = useRef()

  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(false)

  const { register, resetField, handleSubmit, formState } = useForm({
    resolver: zodResolver(schema)
  })

  const { errors } = formState

  const onSubmit = () => {
    setLoading(true)
    emailjs.sendForm('service_0gnducp', 'template_jrb069s', form.current, '5KAs6tPvDndH_HEmm')
    .then((result) => {
        setMessage("Bedankt voor het aanmelden van de nieuwsbrief")
        resetField("email")
        setLoading(false)
    }, (error) => {
        setMessage("Er ging iets mis bij het aanmelden van de nieuwsbrief")
        setLoading(false)
    })
  }

  return (
    <form ref={form} onSubmit={handleSubmit(onSubmit)} className="form__newsletter">
      <div className="input__container newsletter">
        <input 
          type="email"
          placeholder=" "
          className="input newsletter"
          {...register("email")}
        />
        <div className="cut cut-newsletter"></div>
        <label className="placeholder" htmlFor="email">E-mail</label>
        <div className="error">{errors.email?.message}</div>
        <div className="success">{message}</div>
      </div>
      <div className="btn__container-footer">
        <button className="btn">
          {loading ? [<UilSpinner className="spinner" />, <p>Aanmelden</p>] : <p>Aanmelden</p> }
        </button>
      </div>
    </form>
  )
}

export default Newsletter

