const BlockGreen = (block) => {
  return (
    <div className="sales__text">
      <p>
        {block.values}
      </p>
    </div>
  )
}

export default BlockGreen 