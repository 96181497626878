import { UilInstagram, UilLinkedin } from '@iconscout/react-unicons'
import Newsletter from './components/footer/NewsLetter'

const Footer = () => {
  const getCurrentYear = () => { return new Date().getFullYear();};

  return (
    <footer className="footer" id="footer">
      <div className="footer__container container">
        <div className="top-content__container">
          <div className="socials__container">
            <p className="footer__title">Follow me on:</p>
            <a href="https://www.instagram.com/marije.krijgsman/" target="_blank" rel="noreferrer">
              <UilInstagram className="social__icon" />
            </a>
            <a href="https://www.linkedin.com/in/marije-krijgsman/" target="_blank" rel="noreferrer">
              <UilLinkedin className="social__icon" />
            </a>
          </div>
          <div className="newsletter__container">
            <p className="footer__title">Sign up for the newsletter:</p>
            <Newsletter />
          </div>
        </div>
        <div className="bottom-content__container">
          <p>Copyright &#169; {getCurrentYear()} The Hotel Lobby | <a href="https://thehotellobby.nl/privacy-policy" className="footer__link" target="_blank" rel="noreferrer">Privacy Policy</a> | BTW-nummer NL123456789B01 | KvK-nummer 1234 5687</p>
          <p>Created by <a href="https://marbelwebstudios.com" className="footer__link" target="_blank" rel="noreferrer">MarbelWebStudios</a></p>
        </div>
      </div>
    </footer>
  )
}

export default Footer