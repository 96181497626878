import StarSvg from '../svg/StarSvg'

const BlockStarList = (listItem) => {
  return (
    <div className="list__wrapper">
      <span className="list__icon">
        <StarSvg />
      </span>
      <span className="list__text">
        <p>
          {listItem.values}
        </p>
      </span>
    </div>
  )
}

export default BlockStarList