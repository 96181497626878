import LogoSvg from '../components/svg/LogoSvg'
import HomeCard from '../components/home/HomeCard'
import homeCardData from '../../data/homeCardData'

const Home = () => {
  return (
    <section className="home" id="home">
      <div className="home__container container grid">
        <div className="logo__container">
          <LogoSvg />
        </div>
        <HomeCard key={homeCardData.id} values={homeCardData} />
      </div>
    </section>
  )
}

export default Home