import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { string, z } from 'zod'
import emailjs from '@emailjs/browser'
import { UilSpinner } from '@iconscout/react-unicons'

const schema = z.object({
  name: string().min(1, { message: "Naam is verplicht" }),
  companyName: string().min(1, { message: "Bedrijfsnaam is verplicht" }),
  email: string().email({ message: "E-mail is verplicht" }),
  phoneNumber: string().optional(),
  message: string().min(1, { message: "Bericht is verplicht" })
})

const ContactForm = () => {
  const form = useRef()

  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(false)

  const { register, reset, handleSubmit, formState } = useForm({
    resolver: zodResolver(schema)
  })

  const { errors } = formState

  const onSubmit = () => {
    setLoading(true)
    emailjs.sendForm('service_0gnducp', 'template_pdqp3zj', form.current, '5KAs6tPvDndH_HEmm')
    .then((result) => {
        setMessage("Bedankt voor je bericht. Ik neem zo snel mogelijk contact met je op.")
        reset()
        setLoading(false)
    }, (error) => {
        setMessage("Er ging iets mis met het verzenden van de e-mail.")
        setLoading(false)
    })
  }

  return (
    <form ref={form} onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
      <div className="input__container">
        <input 
          type="text"
          placeholder=" "
          className="input"
          {...register("name")}
        />
        <div className="cut cut-short"></div>
        <label className="placeholder" htmlFor="name">Naam</label>
        <div className="error">{errors.name?.message}</div>
      </div>
      <div className="input__container">
        <input 
          type="text"
          placeholder=" "
          className="input"
          {...register("companyName")}
        />
        <div className="cut cut-short"></div>
        <label className="placeholder" htmlFor="companyName">Bedrijfsnaam</label>
        <div className="error">{errors.companyName?.message}</div>
      </div>
      <div className="input__container">
        <input 
          type="text"
          placeholder=" "
          className="input"
          {...register("email")}
        />
        <div className="cut cut-short"></div>
        <label className="placeholder" htmlFor="email">E-mail</label>
        <div className="error">{errors.email?.message}</div>
      </div>
      <div className="input__container">
        <input 
          type="number"
          placeholder=" "
          className="input"
          {...register("phoneNumber")}
        />
        <div className="cut cut-short"></div>
        <label className="placeholder" htmlFor="phoneNumber">Telefoonnummer</label>
        <div className="error">{errors.phoneNumber?.message}</div>
      </div>
      <div className="input__container">
        <textarea 
          className="input textarea" 
          placeholder=" "
          {...register("message")}
        />
        <div className="cut cut-short"></div>
        <label className="placeholder" htmlFor="message">Bericht</label>
        <div className="error">{errors.message?.message}</div>
        <div className="success">{message}</div>
      </div>
      <div className="btn__container-contact">
        <button className="btn">
          {loading ? [<UilSpinner key="1" className="spinner" />, "Verzend"] : "Verzend" }
        </button>
      </div>
    </form>
  )
}

export default ContactForm