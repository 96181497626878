import CheckMarkSvg from '../svg/CheckMarkSvg'

const IntroListItem = (listItem) => {
  return(
    <div className="list__wrapper">
      <span className="list__icon">
        <CheckMarkSvg />
      </span>
      <span className="list__text">
        <p>
          {listItem.values.text}
        </p>
      </span>
    </div>
  )
}

export default IntroListItem