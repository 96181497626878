const BlockOrange = (block) => {
  return (
    <div className="sales__text-orange">
      <p>
        {block.values}
      </p>
    </div>
  )
}

export default BlockOrange