const PitchOne = () => {
  return (
    <div className="section__pitch">
      <p className="pitch__medium">Dat is mogelijk als je je nu inschrijft voor het</p>
      <p className="pitch__large">Boost Jouw Omzet in 90 Dagen</p>
      <p className="pitch__medium">traject van 12 individuele sessies van 1.5 uur</p>
    </div>
  )
}

export default PitchOne