const salesData = {
  id:1,
  blocks: [
    {
      id:1,
      title: "Herken je dit?",
      list: [
        {
          id:1,
          text: "Je bent op de verjaardag van je beste vriendin en een vriend van haar vraagt je wat je doet. " +
                "Vol trots vertel je dat je 2 jaar geleden je droom achterna bent gegaan en nu eigenaresse bent van Hotel " +
                "Belvedere. Hij reageert enthousiast en zegt: “Dan gaan de zaken vast goed! Overal lees ik dat mensen " +
                "weer volop met vakantie gaan en hotels vol zitten.” Van binnen keert het trotse gevoel zich om tot " +
                "een knoop in je maag, want bij jou gaat het helemaal niet zo lekker. Je bent als de dood dat de zaak " +
                "failliet gaat en je droom in duizend stukjes uiteen barst."
        },
        {
          id:2,
          text: "Met knikkende knieën loop je het kantoor van je accountant binnen. Je weet niet waar dit gesprek " +
                "toe zal leiden. Het enige wat je wel weet is dat je cijfers er niet goed uitzien. Het geld vliegt eruit, " +
                "maar vliegt bepaald niet binnen. Hoe ga je het in hemelsnaam voor elkaar krijgen om de uitstaande " +
                "facturen te betalen en je investering ooit terug te verdienen?"
        },
        {
          id:3,
          text: "Het is vrijdagmiddag 15:00. Je beeld je in hoe de kids de school uitrennen, op naar het weekend. Jij " +
                "staat echter achter de receptie te wachten op de weekendgasten die vanaf dit moment kunnen " +
                "inchecken. Verdrietig zie je voor je hoe de andere ouders hun uitgelaten kinderen een knuffel geven " +
                "terwijl die van jou alleen naar huis lopen."
        },
        {
          id:4,
          text: "Geïrriteerd hang je de telefoon op. Weer zo’n slimmerik die vraagt of je de prijs niet kunt aanpassen " +
                "aan het veel goedkopere hotel verderop in het dorp. Het is bepaald geen volle bak, dus de luxe om " +
                "nee te verkopen heb je niet, dus je bent akkoord gegaan. Maar je baalt als een stekker want deze " +
                "“voor een dubbeltje op de eerste rang willen zitten” gasten zijn bepaald niet de mensen die jij graag " +
                "in de watten wilt leggen."
        },
        {
          id:5,
          text: "Eindelijk een gezellige avond uit met vrienden! En terwijl de rest kennelijk lol haalt uit de on-going " +
                "klaagzang over hoge gasprijzen, stijgende rente, dure boodschappen. Stel jij jezelf hopeloos de vraag " +
                "what to do? Want jouw kosten stijgen de pan uit, maar hetzelfde geldt voor je gast dus je kunt je prijs " +
                "toch zeker niet verhogen?"
        }
      ],
      type: "GreenBlock"
    },
    {
      id:2,
      title: "Hoe zou het voor je zijn als?",
      list: [
        {
          id:1,
          text: "Het is een van de eerste heerlijke lentedagen, het zal niet lang meer duren dat de oleanders tegen de " +
                "gevel van het hotel in bloei staan. Über blij en vol trots stap je het hotel binnen. You did it! Een " +
                "financieel gezonde zaak, een droomhotel waarvan je kunt leven."
        },
        {
          id:2,
          text: "Je geeft de accountmanager van de bank een hand en bedankt hem. Bam, de laatste termijn van het " +
                "investeringskrediet is voldaan en jij bent van de lening af. Je voelt je vrijer dan ooit, want het geld dat " +
                "je maandelijks niet meer aflost, is nu pure winst."
        },
        {
          id:3,
          text: "Het is woensdag 11:50 uur en je roept “werkse” tegen Susan. Je holt naar de auto om de kids om " +
                "12:00 uur op te halen van school. Je voelt je zo opgelucht. Susan is het beste wat je ooit is " +
                "overkomen. Een rechterhand zodat je niet meer alles zelf hoeft te doen en je lekker naar de nieuwste " +
                "Disneyfilm kunt op woensdagmiddag."
        },
        {
          id:4,
          text: "Op deze zonnige ochtend zit het ontbijtterras vol. De service loopt gesmeerd en ondertussen maak jij " +
                "aan ieder tafeltje een praatje met je gasten. Veel van de gasten zijn inmiddels kind aan huis. Je voelt " +
                "je vol energie, want om het leven van je gasten mooier maken, ze beter over zichzelf laten voelen, is " +
                "waarvoor jij je in het zweet werkt. En dan betalen ze je hier ook nog voor."
        },
        {
          id:5,
          text: "Je bent op een bijeenkomst van hoteliers uit de regio en er wordt steen en been geklaagd over " +
                "gasten die de hand op de knip houden, verminderde resultaten en budgetten die niet gehaald " +
                "worden. Jij gaat echter niet mee in deze klaagzang, er is namelijk altijd wat! Je vertrouwd op de " +
                "stevige basis waarop je je business hebt gebouwd en kijkt naar de die dingen die wel kunnen om je " +
                "doelen te bereiken."
        }
      ],
      type: "OrangeBlock"
    },
    {
      id:3,
      title: "Aan het einde van het traject",
      list: [
        {
          id:1,
          text: "Weet jij wat je moet doen om een financieel goedlopend hotel te bouwen, zodat jij geen " +
                "nachtmerries meer hebt over failliet gaan. Je bent niet bang bent om te falen, maar runt vol trots " +
                "jouw droomhotel."
        },
        {
          id:2,
          text: "Kun jij de juiste stappen nemen om de omzet te draaien die de kosten dekt, leningen kunt " +
                "terugbetalen en winst maakt. Vrijheid, in plaats van zweetsessies boven de financiële boeken."
        },
        {
          id:3,
          text: "Weet jij hoe goed personeel aannemen je omzet kan verhogen, zodat je minder uren hoeft te " +
                "werken. Dus geen verdriet meer omdat je je een slag in de rondte werkt en hierdoor geen tijd " +
                "overhoudt voor je gezin."
        },
        {
          id:4,
          text: "Heb jij een fundering onder je business gelegd waarop je kunt bouwen zodat jij niet overgeleverd " +
                "bent aan de perikelen van de markt."
        }
      ],
      type: "StarList"
    },
  ],
  pitches: [
    {
      id:1,
      pitch: {
        id:1,
        lines: [
          "", "", ""
        ]
      },
      type: ""
    },
    {
      id:2,
      pitch: {
        id:2,
        lines: [
          "", "", ""
        ]
      },
      type: ""
    }
  ]
}

export default salesData