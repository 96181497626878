const LogoSvg = () => {
  return (
    <svg viewBox="0 0 450 450" xmlns="http://www.w3.org/2000/svg" className="logo">
      <g transform="matrix(0.1, 0, 0, -0.1, 0, 450.000031)" fill="#000000" stroke="none">
        <path d="M1710 4100 c0 -6 32 -10 75 -10 l75 0 0 -230 c0 -223 1 -230 20 -230 19 0 20 7 20 230 l0 230 75 0 c43 0 75 4 75 10 0 6 -63 10 -170 10 -107 0 -170 -4 -170 -10z"/>
        <path d="M2200 3870 c0 -233 1 -240 20 -240 19 0 20 7 20 120 l0 120 110 0 110 0 0 -120 c0 -113 1 -120 20 -120 19 0 20 7 20 240 0 233 -1 240 -20 240 -19 0 -20 -7 -20 -115 l0 -115 -110 0 -110 0 0 115 c0 108 -1 115 -20 115 -19 0 -20 -7 -20 -240z"/>
        <path d="M2640 3870 l0 -240 133 2 132 2 -107 3 -108 4 0 114 0 114 83 4 82 3 -82 2 -83 2 0 105 0 105 105 0 c63 0 105 4 105 10 0 6 -50 10 -130 10 l-130 0 0 -240z"/>
        <path d="M1790 3340 c0 -19 7 -20 260 -20 l260 0 0 -810 0 -810 60 0 60 0 0 810 0 810 260 0 c253 0 260 1 260 20 0 20 -7 20 -580 20 -573 0 -580 0 -580 -20z"/>
        <path d="M50 2410 l0 -710 50 0 50 0 0 360 0 360 345 0 345 0 0 -360 0 -360 55 0 55 0 0 710 0 710 -55 0 -55 0 0 -335 0 -335 -345 0 -345 0 0 335 0 335 -50 0 -50 0 0 -710z"/>
        <path d="M1470 3110 c-155 -22 -286 -126 -366 -295 -63 -130 -78 -207 -78 -405 1 -201 15 -275 80 -409 73 -150 188 -249 325 -280 66 -16 217 -13 284 4 132 35 235 120 303 252 75 146 93 231 93 433 0 228 -37 367 -136 506 -110 154 -289 223 -505 194z m196 -41 c69 -26 178 -125 218 -198 89 -165 125 -408 92 -626 -37 -242 -136 -405 -294 -482 -197 -96 -424 76 -502 381 -81 320 -5 698 174 852 104 90 203 113 312 73z"/>
        <path d="M2760 2410 l0 -710 370 0 c363 0 370 0 370 20 0 20 -7 20 -315 20 l-315 0 0 340 0 340 240 0 c207 0 240 2 240 15 0 13 -33 15 -240 15 l-240 0 0 315 0 315 315 0 c308 0 315 0 315 20 0 20 -7 20 -370 20 l-370 0 0 -710z"/>
        <path d="M3690 2410 l0 -710 370 0 c363 0 370 0 370 20 0 20 -7 20 -315 20 l-315 0 0 690 0 690 -55 0 -55 0 0 -710z"/>
        <path d="M1000 1160 l0 -240 125 0 c77 0 125 4 125 10 0 6 -42 10 -105 10 l-105 0 0 230 c0 223 -1 230 -20 230 -19 0 -20 -7 -20 -240z"/>
        <path d="M1617 1386 c-51 -18 -62 -29 -92 -89 -21 -43 -25 -65 -25 -136 0 -140 54 -225 150 -238 121 -16 199 56 216 200 22 186 -101 315 -249 263z m123 -32 c54 -46 74 -98 75 -194 0 -69 -5 -94 -23 -132 -47 -95 -118 -114 -182 -50 -87 87 -83 293 6 372 43 38 82 39 124 4z"/>
        <path d="M2110 1160 l0 -240 99 0 c86 0 102 3 132 23 79 53 78 147 -2 210 -30 23 -31 25 -11 31 50 16 72 109 38 162 -26 39 -74 54 -171 54 l-85 0 0 -240z m179 206 c53 -28 67 -106 27 -152 -20 -24 -32 -28 -90 -32 l-66 -4 0 101 0 101 50 0 c28 0 63 -6 79 -14z m20 -232 c55 -46 58 -126 6 -171 -21 -19 -38 -23 -91 -23 l-64 0 0 110 0 110 59 0 c49 0 65 -4 90 -26z"/>
        <path d="M2640 1159 l0 -241 111 4 111 3 35 39 c33 36 35 42 30 89 -4 45 -10 56 -45 87 l-40 36 28 16 c19 11 33 31 41 58 11 38 11 46 -7 79 -28 53 -72 71 -176 71 l-88 0 0 -241z m180 206 c59 -31 69 -107 21 -156 -26 -26 -36 -29 -90 -29 l-61 0 0 100 0 100 50 0 c28 0 64 -7 80 -15z m15 -227 c41 -32 59 -81 45 -124 -18 -54 -52 -74 -126 -74 l-64 0 0 110 0 110 59 0 c45 0 65 -5 86 -22z"/>
        <path d="M3180 1390 c0 -6 32 -68 70 -139 l70 -128 0 -101 0 -102 25 0 25 0 0 105 0 104 70 127 c39 69 70 130 70 135 0 28 -23 -4 -80 -111 -36 -66 -68 -120 -72 -120 -4 0 -35 54 -70 120 -52 99 -67 120 -85 120 -13 0 -23 -5 -23 -10z"/>
        <path d="M100 1105 c0 -13 48 -15 370 -15 322 0 370 2 370 15 0 13 -48 15 -370 15 -322 0 -370 -2 -370 -15z"/>
        <path d="M3650 1095 c0 -13 47 -15 365 -15 318 0 365 2 365 15 0 13 -47 15 -365 15 -318 0 -365 -2 -365 -15z"/>
      </g>
    </svg>
  )
}

export default LogoSvg