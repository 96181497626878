const NavItem = (navItem) => {
  return (
    <li className="nav__item">
      <a href={navItem.values.href} className="nav__link active-link">
        <navItem.values.icon className="nav__icon" /> {navItem.values.title}
      </a>
    </li>
  )
}

export default NavItem