import ContactForm from '../components/contact/ContactForm'

const Contact = () => {
  return (
    <section className="contact section" id="contact">
      <div className="contact__container container">
        <h2 className="section__title">Contact</h2>
        <p className="section__subtitle">
          Voor vragen kun je gebruik maken van onderstaand contactformulier, vul hier je gegevens in. Ik zal je bericht zo snel mogelijk beantwoorden.
        </p>
        <div className="form-container">
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default Contact