import aboutData from "../../data/aboutData"
import AppointmentButton from "../components/AppointmentButton"
import AboutBlock from "../components/about/AboutBlock"

const About = () => {
  const aboutBlocks = []

  aboutData.blocks.forEach(aboutBlock => {
    aboutBlocks.push(
      <AboutBlock key={aboutBlock.id} values={aboutBlock} />
    )
  })

  return (
    <section className="about section" id="about">
      <h2 className="section__title">{aboutData.title}</h2>
      <div className="about__container">
        <div className="about__wrapper">
          {aboutBlocks}
        </div>
        <div className="about__subtext">
          <h2 className="subtext__title">{aboutData.subtextTitle}</h2>
          <p className="subtext__text">{aboutData.subText}</p>
          <AppointmentButton />
        </div>
      </div>
    </section>
  )
}

export default About