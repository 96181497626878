import introData from '../../data/introData'
import IntroListItem from '../components/undefined/IntroListItem'
import AppointmentButton from '../components/AppointmentButton'

const Introduction = () => {
  const listItems = []

  introData.list.forEach(listItem => {
    listItems.push(
      <IntroListItem key={listItem.id} values={listItem} />
    )
  })

  return (
    <section className="introduction section" id="introduction">
      <div className="introduction__container container">
        <h2 className="section__title">
          {introData.title}
          </h2>
        <div className="intro__start">
          <p>{introData.intro}</p>
          <p className="intro__question">{introData.introSubtitle}</p>
        </div>
        <div className="list__container">
          {listItems}
        </div>
        <div className="intro__slot">
          <p className="intro__question">{introData.outtroSubtitle}</p>
          <p>{introData.outtro}</p>
          <AppointmentButton />
        </div>
      </div>
    </section>
  )
}

export default Introduction