const introData = {
  id:1,
  // title:"Undefined Title",
  intro:"Jij hebt je stoutste droom verwezenlijkt: jij kocht een hotel. " +
        "Je wilde hotelier worden omdat je het prachtig vindt om met mensen te werken, " +
        "jij wilt je gasten in de watten leggen, hun leven mooier maken. " +
        "Maar je vraagt je iedere dag af hoe het toch mogelijk is dat je nauwelijks de kosten kunt dekken. "+
        "Dit is niet hoe je het je had voorgesteld, als je een geweldig product aanbiedt dan komen de gasten en het geld toch gewoon vanzelf?",
  introSubtitle: "Herken je dit?",
  list: [
    {
      id:1,
      text: "Het is zonsopkomst en je rijdt de parking van je hotel op. Het is precies het plaatje waar je altijd van " +
            "droomde, helemaal nu het gekust wordt door de eerste zonnestralen. De ultiem blije kriebel in je " +
            "buik vormt zich ineens om tot een knoop. Wat nu als je faalt, de zaak failliet gaat, je droom in duigen " +
            "valt?"
    },
    {
      id:2,
      text: "Je staart naar het Excel overzicht met de hotel financiën. Het zweet breekt je uit als je kijkt naar de " +
            "kosten die eruit vliegen en de inkomsten die niet hoog genoeg zijn. Hoe moet je in godsnaam alles " +
            "betalen en de investeringen die je hebt gedaan terugverdienen?"
    },
    {
      id:3,
      text: "Het is zaterdagavond en na een lange dag vertrekken de laatste gasten naar hun kamers. Eindelijk " +
            "kun jij naar je gezin. Je kijkt op de klok en verdrietig realiseer je je dat de kids allang op bed liggen. En " +
            "je vraagt je af wanneer je ze voor de laatste keer op bed hebt gedaan?"
      
    },
    {
      id:4,
      text: "Je ploft neer op de stoel achter de receptie. Je zou dankbaar moeten zijn, want het hotel is eindelijk " +
            "een keer volgeboekt. Maar je voelt je leeg en geïrriteerd. De gasten die zojuist hebben ingecheckt " +
            "liepen alleen maar te zeurzemelen, typische gevalletjes voor een dubbeltje op de eerste rang. Dit zijn " +
            "niet de mensen die jij in de watten wilt leggen!"
      
    },
    {
      id:5,
      text: "Je kijkt naar het nieuws en krijgt een knoop in je maag van de berichtgeving over de inflatie. De " +
            "pandemie eindelijk voorbij, nadert de volgende crisis alweer. Je hebt zelf ook gemerkt hoe sterk je " +
            "kosten stijgen, maar je kunt je prijs voor de gast ook niet verhogen. Die heeft tenslotte ook minder te " +
            "besteden. Hopeloos plof je neer, what to do?"
    }
  ],
  outtroSubtitle: "Als er nu niets veranderd",
  outtro: "Jouw hoteldroom dreigt te veranderen in een nachtmerrie. Je werkt je kapot omdat je geen " +
          "personeel in dienst neemt om kosten te besparen. Omdat het water je aan de lippen staat, ben je " +
          "desperate voor gasten waar je doodongelukkig van wordt. Uiteindelijk zijn de kosten te hoog, er " +
          "moet teveel geld bij, en jij bent uitgeblust. Het hotel wordt verkocht. De droom spat uiteen."
}

export default introData