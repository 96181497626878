const HomeCard = (homeCard) => {
  return(
    <div className="homecard__container">
      <span className="home__title">
        <h1>{homeCard.values.title}</h1>
      </span>
      <span className="home__subtitle">
        <p>{homeCard.values.subtitle}</p>
      </span>
      <div className="btn__container">
        <a href="#introduction" className="btn">
          <span>{homeCard.values.buttonText}</span>
        </a>
      </div>
    </div>
  )
}

export default HomeCard