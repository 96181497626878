import salesData from '../../data/salesData'
import SalesBlock from '../components/sales/SalesBlock'
import ImageBackgroundTwo from '../components/ImageBackgroundTwo'
import PitchOne from '../components/sales/PitchOne'
import PitchTwo from '../components/sales/PitchTwo'

const Sales = () => {
  return (
    <section className="sales section" id="sales">
      <SalesBlock key={salesData.blocks[0].id} values={salesData.blocks[0]} />
      <ImageBackgroundTwo />
      <SalesBlock key={salesData.blocks[1].id} values={salesData.blocks[1]} />
      <PitchOne />
      <SalesBlock key={salesData.blocks[2].id} values={salesData.blocks[2]} />
      <PitchTwo />
    </section>
  )
}

export default Sales