import AppointmentButton from '../AppointmentButton'

const PitchTwo = () => {
  return (
    <div className="section__pitch">
      <p className="pitch__xlarge">De investering is</p>
      <p className="pitch__xlarge">€ 1997,- <span className="pitch__xsmall">(exclusief BTW)</span></p>
      <p className="pitch__small">Of 3 termijnen van € 800,- per maand</p>
      <AppointmentButton />
    </div>
  )
}

export default PitchTwo